import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  LoginParams,
  LoginGoogleParams,
  LoginResponse,
  SignupParams,
  SimpleMessageResponse,
  GetMeResponse,
  CreateInstanceParams,
  CreateInstanceResponse,
  Instance,
  Batch,
  GetEvasionParams,
  Evasion,
  Recommender,
  GetRecommendersParams,
  Indicator,
  GetIndicatorsParams,
  GetMetricsParams,
  Metric,
  Course,
  GetCoursesParams,
  PasswordRecoverySolicitateParams,
  ChangePasswordByRecoverySolicitateParams,
  UpdateUser,
  EditInstanceParams,
  NewBatchParams,
  DeleteBatchParams,
  PaymentMethod,
  Token,
  AcceptPlan,
  GetStudentsListByIdsRequest,
  GetStudentsListByIdsResponse,
  GetTutorsListByIdsRequest,
  GetRecommenderHistoryQS,
  SendRecommenderByEmailBody,
} from "../types";

export const AssisApi = createApi({
  reducerPath: "assis-api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ASSIS_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token.replaceAll('"', "")}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Querys
    getMe: builder.query<GetMeResponse, void>({
      query: () => {
        return { url: `users/me` };
      },
    }),
    getCourses: builder.query<Course[], GetCoursesParams>({
      query: (params: GetCoursesParams) => {
        let qs = "";
        Object.entries(params).forEach((value) => {
          qs += `${value[0]}=${value[1]}`;
        });
        return { url: `courses?${qs}` };
      },
    }),
    confirmEmail: builder.query<SimpleMessageResponse, string>({
      query: (emailConfirmId) => {
        return { url: `users/confirm-email/${emailConfirmId}` };
      },
    }),
    getIntances: builder.query<Instance[], void>({
      query: () => {
        return { url: `instances` };
      },
    }),
    getIntanceBatchs: builder.query<Batch[], string>({
      query: (instanceId) => {
        return { url: `instances/${instanceId}/batchs` };
      },
    }),
    getBatch: builder.query<Batch, string>({
      query: (batchId) => {
        return { url: `batchs/${batchId}` };
      },
    }),
    validatePasswordSolicitation: builder.query<SimpleMessageResponse, string>({
      query: (passwordSolicitationId) => {
        return { url: `users/password-recovery/${passwordSolicitationId}` };
      },
    }),
    getPaymentsMethod: builder.query<PaymentMethod[], null>({
      query: () => {
        return { url: `users/payments-method/` };
      },
    }),
    getInvoices: builder.query<any, void>({
      query: () => {
        return { url: `transactions/` };
      },
    }),
    getRecommendersHistory: builder.mutation<any[], GetRecommendersParams>({
      query: (body) => {
        return { url: `recommender/history`, body, method: "post" };
      },
    }),

    // Mutations
    sendAcceptTerm: builder.mutation<SimpleMessageResponse, void>({
      query: () => {
        return { url: `users/accept-term`, method: "post" };
      },
    }),
    login: builder.mutation<LoginResponse, LoginParams>({
      query: (body) => {
        return { url: `auth/login`, method: "post", body };
      },
    }),
    loginGoogle: builder.mutation<LoginResponse, LoginGoogleParams>({
      query: (body) => {
        return { url: `auth/login/google`, method: "post", body };
      },
    }),
    signup: builder.mutation<SimpleMessageResponse, SignupParams>({
      query: (body) => {
        return { url: `users`, method: "post", body };
      },
    }),
    createInstance: builder.mutation<
      CreateInstanceResponse,
      CreateInstanceParams
    >({
      query: (body) => {
        return { url: `instances`, method: "post", body };
      },
    }),
    editInstance: builder.mutation<SimpleMessageResponse, EditInstanceParams>({
      query: (body) => {
        return { url: `instances/${body.id}`, method: "put", body };
      },
    }),
    deleteInstance: builder.mutation<SimpleMessageResponse, string>({
      query: (instanceId) => {
        return { url: `instances/${instanceId}`, method: "delete" };
      },
    }),
    // newBatch: builder.mutation<SimpleMessageResponse, NewBatchParams>({
    //   query: (body) => {
    //     return { url: `batchs`, method: "post", body };
    //   },
    // }),
    newBatch: builder.mutation<SimpleMessageResponse, NewBatchParams>({
      query: (body) => {
        return {
          url: `instances/${body.instanceId}/batchs`,
          method: "post",
          body: body.formData,
        };
      },
    }),
    deleteBatch: builder.mutation<SimpleMessageResponse, DeleteBatchParams>({
      query: ({ instanceId, batchId }) => {
        return {
          url: `instances/${instanceId}/batchs/${batchId}`,
          method: "delete",
        };
      },
    }),
    evasion: builder.mutation<Evasion, GetEvasionParams>({
      query: (body) => {
        return { url: `batchs/evasion`, method: "post", body };
      },
    }),
    metrics: builder.mutation<Metric[], GetMetricsParams>({
      query: (body) => {
        return { url: `metrics/`, method: "post", body };
      },
    }),
    indicators: builder.mutation<Indicator[], GetIndicatorsParams>({
      query: (body) => {
        return { url: `indicators/`, method: "post", body };
      },
    }),
    recommenders: builder.mutation<Recommender[], GetRecommendersParams>({
      query: (body) => {
        return { url: `recommender/`, method: "post", body };
      },
    }),
    sendChangeEmail: builder.mutation<any, UpdateUser>({
      query: (body: UpdateUser) => {
        return { url: `users/send-transfer`, method: "post", body };
      },
    }),
    confirmChangeEmail: builder.mutation<any, Token>({
      query: (body: Token) => {
        return { url: `users/send-transfer`, method: "PATCH", body };
      },
    }),
    updateUser: builder.mutation<any, UpdateUser>({
      query: (body: UpdateUser) => {
        return { url: `users`, method: "PATCH", body };
      },
    }),
    passwordRecoverySolicitate: builder.mutation<
      SimpleMessageResponse,
      PasswordRecoverySolicitateParams
    >({
      query: (body) => {
        return { url: `users/password-recovery/`, method: "post", body };
      },
    }),
    changePasswordByRecoverySolicitate: builder.mutation<
      SimpleMessageResponse,
      ChangePasswordByRecoverySolicitateParams
    >({
      query: (body) => {
        return {
          url: `users/password-recovery/${body.code}/change-password`,
          method: "post",
          body,
        };
      },
    }),
    acceptPlan: builder.mutation<SimpleMessageResponse, AcceptPlan>({
      query: (body) => {
        return {
          url: `transactions/accept-plan`,
          method: "post",
          body,
        };
      },
    }),
    cancelPlan: builder.mutation<void, void>({
      query: () => {
        return { url: "transactions/cancel", method: "put" };
      },
    }),
    selfRemove: builder.mutation<any, any>({
      query: () => {
        return { url: "users/self-remove", method: "get" };
      },
    }),
    getStudentsListByIds: builder.mutation<
      GetStudentsListByIdsResponse[],
      GetStudentsListByIdsRequest
    >({
      query: (body) => {
        return { url: "students/get-by-ids", method: "post", body };
      },
    }),
    getTutorsListByIds: builder.mutation<
      GetStudentsListByIdsResponse[],
      GetTutorsListByIdsRequest
    >({
      query: (body) => {
        return { url: "tutors/get-by-ids", method: "post", body };
      },
    }),
    sendRecommenderByEmail: builder.mutation<void, SendRecommenderByEmailBody>({
      query: (body) => {
        return { url: "recommender/notify-email", method: "post", body };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useConfirmEmailQuery,
  useGetIntancesQuery,
  useGetIntanceBatchsQuery,
  useGetCoursesQuery,
  useGetBatchQuery,
  useValidatePasswordSolicitationQuery,
  useGetInvoicesQuery,

  useSendAcceptTermMutation,
  useLoginMutation,
  useLoginGoogleMutation,
  useSignupMutation,
  useCreateInstanceMutation,
  useEditInstanceMutation,
  useDeleteInstanceMutation,
  useNewBatchMutation,
  useDeleteBatchMutation,
  useEvasionMutation,
  useMetricsMutation,
  useIndicatorsMutation,
  useRecommendersMutation,
  usePasswordRecoverySolicitateMutation,
  useChangePasswordByRecoverySolicitateMutation,
  useSendChangeEmailMutation,
  useUpdateUserMutation,
  useConfirmChangeEmailMutation,
  useSelfRemoveMutation,
  useAcceptPlanMutation,
  useGetStudentsListByIdsMutation,
  useGetTutorsListByIdsMutation,
  useCancelPlanMutation,
  useGetRecommendersHistoryMutation,
  useSendRecommenderByEmailMutation,
} = AssisApi;
