import Screen from "../../components/Screen";

import { Tab, Tabs } from "@mui/material";
import { UserPlan } from "../../types";
import "./styles.sass";
import { useState } from "react";
import { Recommend, Summarize } from "@mui/icons-material";
import Batchs from "./batchs";
import Recommendations from "./recomendations";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function Instance() {
  const [value, setValue] = useState(0);

  const userInfo = useSelector(
    (state: RootState) => state.auth.authenticatedUser
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // @TODO remover novamente o go back do detalhe da recomendação de rota e adicionar novamente na pastas e passar a controlar a tab via redux

  return (
    <div className="instance-screen">
      <Screen>
        <div className="page-header">
          {/* <Typography variant="h6" className="title" alignSelf={"center"}>
            {selectedInstance.description}
          </Typography> */}
          <Tabs value={value} onChange={handleChange} textColor="secondary">
            <Tab
              icon={<Summarize fontSize="small" />}
              iconPosition="start"
              value={0}
              label="Lista de análises"
            />
            {/* {userInfo.plan === UserPlan.PREMIUM && ( */}
            <Tab
              icon={<Recommend fontSize="small" />}
              iconPosition="start"
              value={1}
              label="Recomendações"
            />
            {/* )} */}
          </Tabs>
        </div>
        {value === 0 && <Batchs />}
        {value === 1 && <Recommendations />}
      </Screen>
    </div>
  );
}
