import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import UploadIcon from "@mui/icons-material/CloudUpload";
import "./styles.sass";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNewBatchMutation } from "../../services/AssisApi";
import {
  UploadBatch,
  UploadCourse,
  UploadErro,
  UploadStudent,
  UploadTutor,
} from "../../types";
import { RTKFetchError } from "../../services/Helpers";
import { useSnackbar } from "notistack";
import DocIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";

const fileTypes = ["JSON"];
interface Props {
  handleOpen: () => void;
}
export default function BatchUpload({ handleOpen }: Props) {
  let { instanceId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [file, setFile] = useState({} as File);
  const [fileError, setFileError] = useState("");
  const [fileContent, setFileContent] = useState<UploadBatch>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [newBatch, { isLoading, isError, data, error, reset }] =
    useNewBatchMutation();

  const handleChange = (file: File) => {
    setFileError("");
    setFile(file);
    let reader = new FileReader();
    enqueueSnackbar("Verificando arquivo!", {
      key: "verify",
      autoHideDuration: null,
      variant: "info",
    });
    reader.onload = function (event: any) {
      const fileContent = JSON.parse(event.target.result) as UploadBatch;
      const errorData: UploadErro[] = [];

      const uniqueCoursesArr: UploadCourse[] = [];
      const uniqueTutorsArr: UploadTutor[] = [];
      const uniqueStudentsArr: UploadStudent[] = [];

      // const excludedCourses: number[] = [5, 25, 26, 27, 28];
      const excludedCourses: number[] = [];

      fileContent.courses.forEach((course) => {
        if (
          !excludedCourses.some((c) => c === course.id) &&
          !uniqueCoursesArr.some((uItem) => uItem.id === course.id)
        ) {
          uniqueCoursesArr.push(course);
        }
      });

      fileContent.tutors.forEach((tutor) => {
        if (!uniqueTutorsArr.some((uItem) => uItem.id === tutor.id)) {
          uniqueTutorsArr.push(tutor);
        }
      });

      fileContent.students.forEach((student) => {
        if (
          !excludedCourses.some((c) => c === student.course) &&
          !uniqueStudentsArr.some((uItem) => uItem.id === student.id)
        ) {
          uniqueStudentsArr.push(student);
        }
      });

      // Validations

      fileContent.courses.forEach((course) => {
        if (excludedCourses.some((c) => c === course.id)) return;
        if (!course.tutors) {
          closeSnackbar("verify");
          enqueueSnackbar(
            `O curso ${course.name} de id ${course.id} não contém a lista de tutores, a analise não pode ser realizada!`,
            { variant: "error" }
          );
          throw new Error("Course without tutors");
        }
        if (!course.students) {
          closeSnackbar("verify");
          enqueueSnackbar(
            `O curso ${course.name} de id ${course.id} não contém a lista de alunos, a analise não pode ser realizada!`,
            { variant: "error" }
          );
          throw new Error("Course without students");
        }
        course.tutors.forEach((cTutor) => {
          if (!uniqueTutorsArr.some((uTutor) => uTutor.id === cTutor))
            errorData.push({
              type: "warning",
              message: `O curso ${course.name} de id ${course.id} tem um tutor que não existe na lista de tutores de id ${cTutor}`,
            });
        });
        course.students.forEach((cStudent) => {
          if (!uniqueStudentsArr.some((uStudent) => uStudent.id === cStudent))
            errorData.push({
              type: "warning",
              message: `O curso ${course.name} de id ${course.id} tem um aluno que não existe na lista de alunos de id ${cStudent}`,
            });
        });
        if (course.students.length === 0)
          errorData.push({
            type: "error",
            message: `O curso ${course.name} de id ${course.id} será descartado, pois não existe nenhum aluno matriculado`,
          });
        else if (
          !fileContent.students.some(
            (fStudent) => fStudent.course === course.id
          )
        )
          errorData.push({
            type: "error",
            message: `O curso ${course.name} de id ${course.id} será descartado, pois não existe nenhuma matrícula vinculada na lista de alunos`,
          });
      });

      fileContent.students.forEach((student) => {
        if (excludedCourses.some((c) => c === student.course)) return;
        if (!student.tutors) {
          closeSnackbar("verify");
          enqueueSnackbar(
            `Uma matricula do ${student.name} de id ${student.id} não contém a lista de tutores, a analise não pode ser realizada!`,
            { variant: "error" }
          );
          throw new Error("Student without tutors");
        }
        if (!student.course) {
          closeSnackbar("verify");
          enqueueSnackbar(
            `Uma matricula do ${student.name} de id ${student.id} não contém o id do curso, a analise não pode ser realizada!`,
            { variant: "error" }
          );
          throw new Error("Student without students");
        }
        student.tutors.forEach((sTutor) => {
          if (!uniqueTutorsArr.some((uTutor) => uTutor.id === sTutor))
            errorData.push({
              type: "warning",
              message: `A matrícula do aluno ${student.name} de id ${student.id} no curso de id ${student.course} tem um tutor que não existe na lista de tutores de id ${sTutor}.`,
            });
        });
        if (!uniqueCoursesArr.some((uCourse) => uCourse.id === student.course))
          errorData.push({
            type: "warning",
            message: `A matrícula do aluno ${student.name} de id ${student.id} tem um curso que não existe na lista de cursos de id ${student.course}.`,
          });
        if (!student.course)
          errorData.push({
            type: "error",
            message: `Existe uma matrícula do aluno ${student.name} de id ${student.id} que não tem curso vinculado e será descartada.`,
          });
      });

      closeSnackbar("verify");
      setFileContent({
        courses: uniqueCoursesArr,
        tutors: uniqueTutorsArr,
        students: uniqueStudentsArr,
        errors: errorData,
      });
    };
    reader.readAsText(file);
  };

  const onTypeError = (error: string) => {
    setFileError("Arquivo inválido. Selecione um arquivo no formato JSON");
  };

  const confirmSend = async () => {
    const formData = new FormData();
    formData.append("file", file);
    enqueueSnackbar("Enviando Analise, aguarde um pouco!", {
      variant: "info",
      key: "loading",
    });
    await newBatch({ instanceId: instanceId as string, formData });
  };

  const cancelSend = async () => {
    setFile({} as File);
    setFileContent(undefined);
  };

  useEffect(() => {
    if (data) {
      closeSnackbar("loading");
      enqueueSnackbar("Analise enviada com sucesso", { variant: "success" });
      // setFile({} as File);
      // setFileContent(undefined);
      navigate(-1);
    }
  }, [data]);

  return (
    <Grid container spacing={2} id={"wrapper-batch-upload"} mt={2}>
      {fileContent !== undefined ? (
        <>
          <Grid item xs={7} className={"table-info"}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{fileContent.courses.length} Curso(s)</TableCell>
                    <TableCell align="right">
                      {fileContent.tutors.length} Tutor(es)
                    </TableCell>
                    <TableCell align="right">
                      {fileContent.students.length} Aluno(s)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fileContent.courses
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((course) => (
                      <TableRow key={course.id}>
                        <TableCell>{course.name}</TableCell>
                        <TableCell align="right">
                          {course.tutors.length}
                        </TableCell>
                        <TableCell align="right">
                          {course.students.length}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={fileContent.courses.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Divider />
            <Box className={"buttons"} mt={2} mb={2}>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                color="error"
                onClick={cancelSend}
              >
                Cancelar
              </Button>
              <Button variant="contained" sx={{ mr: 1 }} onClick={confirmSend}>
                Confirmar envio
              </Button>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {fileContent.errors.map((er) => (
              <Alert severity={er.type} sx={{ mb: 2 }}>
                <Typography>{er.message}</Typography>
              </Alert>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} className="links">
            <Button onClick={handleOpen} variant="text">
              Documentação
              <DocIcon fontSize="small" />
            </Button>
            <Button
              download
              target={"_blank"}
              href="https://firebasestorage.googleapis.com/v0/b/assis-console-dev.appspot.com/o/dev%2Fexample.json?alt=media&token=a7933ce9-9944-48ce-acf0-6dcfd725d1f6"
            >
              Exemplo de envio
              <DownloadIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={12} className="batch-upload">
            {fileError && (
              <Alert
                className="error"
                severity="warning"
                onClose={(event) => {
                  event.stopPropagation();
                  setFileError("");
                }}
              >
                {fileError}
              </Alert>
            )}
            {isError && (
              <Alert
                className="error-message"
                severity="error"
                onClose={(event) => {
                  event.stopPropagation();
                  reset();
                }}
              >
                {RTKFetchError(error)?.message}
              </Alert>
            )}

            {data && (
              <Alert
                className="success-message"
                severity="success"
                onClose={(event) => {
                  event.stopPropagation();
                  reset();
                }}
              >
                {data.message}
              </Alert>
            )}

            {fileContent === undefined && (
              <FileUploader
                types={fileTypes}
                handleChange={handleChange}
                onTypeError={onTypeError}
              >
                <UploadIcon fontSize="large" className="icon" />
                <Typography variant="body1" className="title">
                  Solte seus arquivos aqui ou
                </Typography>
                <Button variant="contained">
                  Selecione um arquivo pra enviar
                </Button>
                <Typography variant="body2">
                  Para uma operação confiável, anexe um arquivo de tamanho menor
                  que 1GB.
                </Typography>
              </FileUploader>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
