import {
  ArrowDropDown,
  Event,
  ExpandMore,
  FilterAlt,
  Groups,
  InfoOutlined,
  LocalOffer,
  MenuBook,
  Recommend,
  School,
  StackedLineChart,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridCellValue,
  GridColumnHeaderParams,
  GridColumns,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import { ptBR } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useChangePasswordByRecoverySolicitateMutation,
  useGetRecommendersHistoryMutation,
  useRecommendersMutation,
} from "../../services/AssisApi";
import { RootState } from "../../store";
import {
  selectRecommendation,
  setFilters,
} from "../../store/slices/recommendation";
import {
  Class,
  Course,
  GetRecommendersParams,
  Recommender,
  Tutor,
} from "../../types";
import RecommendationDetails from "../../components/RecommendationDetails";

export default function Recommendations() {
  const { instanceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filters: GetRecommendersParams = useSelector(
    (state: RootState) => state.recommendations.filters
  );

  const [recommendationsTypes, setRecommendationsTypes] = useState<string[]>(
    []
  );
  const [recommendationsCourses, setRecommendationsCourses] = useState<
    string[]
  >([]);
  const [recommendationsClasses, setRecommendationsClasses] = useState<
    string[]
  >([]);
  const [recommendationsTutors, setRecommendationsTutors] = useState<string[]>(
    []
  );
  const [recommendationsTags, setRecommendationsTags] = useState<string[]>([]);

  const [recommenders, recommendersResult] = useRecommendersMutation();
  const [recommendersHistory, recommendersHistoryResult] =
    useGetRecommendersHistoryMutation();
  // const {
  //   data: recommendersHistory,
  //   isLoading: rhIsLoading,
  //   isSuccess: rhIsSuccess,
  // } = useGetRecommendersHistoryQuery({
  //   instance: instanceId as string,
  // });

  useEffect(() => {
    getRecommenders();
  }, [filters]);

  // @todo Remover primeira parte do if (course.name) vai ser campo obrigatório!
  useEffect(() => {
    const recTypes = recommendationsTypes;
    const recCourses = recommendationsCourses;
    const recClasses = recommendationsClasses;
    const recTutors = recommendationsTutors;
    const recTags = recommendationsTags;
    if (recommendersResult.data) {
      console.time("carregando lista");
      recommendersResult.data.forEach((el) => {
        if (!recTypes.includes(el.type)) recTypes.push(el.type);
        if (el.course?.name && !recCourses.includes(el.course.name))
          recCourses.push(el.course.name);
        if (
          filters["course.name"] &&
          el.class?.name &&
          !recClasses.includes(el.class.name)
        )
          recClasses.push(el.class.name);
        if (el.tutor?.name && !recTutors.includes(el.tutor.name))
          recTutors.push(el.tutor.name);
        el.tags?.forEach((tag: string) => {
          if (!recTags.includes(tag)) recTags.push(tag);
        });
      });
      setRecommendationsTypes(recTypes);
      setRecommendationsCourses(recCourses);
      setRecommendationsClasses(recClasses);
      setRecommendationsTutors(recTutors);
      setRecommendationsTags(recTags);
      console.timeEnd("carregando lista");
    } else {
      setRecommendationsTypes([]);
      setRecommendationsCourses([]);
      setRecommendationsClasses([]);
      setRecommendationsTutors([]);
      setRecommendationsTags([]);
    }
  }, [recommendersResult.data]);

  const columns: GridColumns = [
    {
      field: "percent",
      // headerName: "Modo de envio",
      // width: 150,
      flex: 0.9,
      // sortable: false,
      renderCell: (params: GridCellParams<string>) => (
        <Tooltip title={`${params.row.name}`}>
          <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
            <strong>{params.row.name}</strong>
          </Typography>
        </Tooltip>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <Recommend />
          <Typography>Recomendação</Typography>
        </Stack>
      ),
    },
    {
      field: "course",
      // headerName: "Modo de envio",
      // width: 150,
      flex: 0.35,
      sortComparator: (v1, v2) =>
        (v1 as Course)?.name.localeCompare((v2 as Course)?.name),
      renderCell: (params: GridCellParams<string>) => (
        <Tooltip title={`${params.row?.course?.name || ""}`}>
          <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
            {params.row?.course?.name || ""}
          </Typography>
        </Tooltip>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <MenuBook />
          <Typography>Curso</Typography>
        </Stack>
      ),
    },
    {
      field: "class",
      // headerName: "Modo de envio",
      // width: 150,
      flex: 0.35,
      sortComparator: (v1, v2) =>
        (v1 as Class)?.name.localeCompare((v2 as Class)?.name),
      renderCell: (params: GridCellParams<string>) => (
        <Tooltip title={`${params.row?.class?.name || ""}`}>
          <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
            {params.row?.class?.name || ""}
          </Typography>
        </Tooltip>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <Groups />
          <Typography>Turma</Typography>
        </Stack>
      ),
    },
    {
      field: "tutor",
      // headerName: "Modo de envio",
      // width: 150,
      flex: 0.35,
      sortComparator: (v1, v2) =>
        (v1 as Tutor)?.name.localeCompare((v2 as Tutor)?.name),
      renderCell: (params: GridCellParams<string>) => (
        <Tooltip title={`${params.row?.tutor?.name || ""}`}>
          <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
            {params.row?.tutor?.name || ""}
          </Typography>
        </Tooltip>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <School />
          <Typography>Tutor</Typography>
        </Stack>
      ),
    },
    {
      field: "date",
      // headerName: "Modo de envio",
      // width: 150,
      flex: 0.3,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) =>
        moment(params.value?.toString()).format("DD/MM/YYYY"),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Tooltip title="A data em que a análise foi enviada">
          <Stack spacing={1} direction={"row"} mr={2}>
            <Event />
            <Typography>Data</Typography>
          </Stack>
        </Tooltip>
      ),
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    dispatch(selectRecommendation(params.row));
    navigate("recommendation");
  };

  const getRecommenders = () => {
    recommenders({ ...filters, instance: instanceId as string });
    recommendersHistory({ ...filters, instance: instanceId as string });
  };

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleFilter = (value: string, filterType: string) => {
    let newFilters = { ...filters };
    newFilters[filterType] = value;
    dispatch(setFilters(newFilters));
    handleCloseMenu();
  };

  const handleDeleteFilter = (filterType: string) => {
    let newFilters = { ...filters };
    delete newFilters[filterType];
    dispatch(setFilters(newFilters));
  };

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      key: "selection",
    },
  ]);
  const [anchorElDate, setAnchorElDate] = useState<null | HTMLElement>(null);
  const openDate = Boolean(anchorElDate);
  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDate(event.currentTarget);
  };
  const handleCloseDate = () => {
    setAnchorElDate(null);
  };

  const handleDateChange = (value: RangeKeyDict) => {
    let newFilters = { ...filters };
    const dateRange = value.selection;
    setState([value.selection]);
    newFilters.date = {
      $gte: moment(dateRange.startDate).format("DD/MM/YYYY"),
      $lte: moment(dateRange.endDate).format("DD/MM/YYYY"),
    };
    dispatch(setFilters(newFilters));
  };

  const [anchorElInfo, setAnchorElInfo] = useState<null | SVGSVGElement>(null);
  const openInfo = Boolean(anchorElInfo);
  const handlePopoverInfoOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorElInfo(event.currentTarget);
  };
  const handlePopoverInfoClose = () => {
    setAnchorElInfo(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button
                variant={"outlined"}
                startIcon={<FilterAlt />}
                endIcon={<ArrowDropDown />}
                fullWidth
                onClick={handleClickMenu}
              >
                Adicionar Filtro
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                variant={"outlined"}
                startIcon={<Event />}
                endIcon={<ArrowDropDown />}
                // fullWidth
                onClick={handleClickDate}
              >
                {filters.date
                  ? `${filters.date.$gte} ~ ${filters.date.$lte}`
                  : "Filtrar por data"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {filters["class.name"] && (
            <Chip
              icon={<Groups />}
              color="primary"
              label={filters["class.name"]}
              onDelete={() => handleDeleteFilter("class.name")}
            />
          )}
          {filters["course.name"] && (
            <Chip
              icon={<MenuBook />}
              color="primary"
              label={filters["course.name"]}
              onDelete={() => handleDeleteFilter("course.name")}
            />
          )}
          {filters["tutor.name"] && (
            <Chip
              icon={<School />}
              color="primary"
              label={filters["tutor.name"]}
              onDelete={() => handleDeleteFilter("tutor.name")}
            />
          )}
          {filters.tag && (
            <Chip
              icon={<LocalOffer />}
              color="primary"
              label={filters.tag}
              onDelete={() => handleDeleteFilter("tag")}
            />
          )}
          {filters.type && (
            <Chip
              icon={<Recommend />}
              color="primary"
              label={filters.type}
              onDelete={() => handleDeleteFilter("type")}
            />
          )}
        </Grid>
        <Grid item xs={3} mt={2}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  pl={1}
                >
                  <Recommend />
                  <Typography variant={"h4"}>Visão Geral</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {recommendersHistoryResult.data?.length === 0 ? (
                  <Typography variant={"h6"}>nenhuma recomendação</Typography>
                ) : (
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={recommendersHistoryResult.data?.reduce(
                      (prev: any[], cur: any[], index: number) => {
                        if (index === 0)
                          cur.slice(0).forEach((v, i) => (prev[i] = [v, 0]));
                        else cur.slice(0).forEach((v, i) => (prev[i][1] += v));

                        return prev;
                      },
                      [["Recomendação", "Ocorrências"]] as any[]
                    )}
                    loader={<CircularProgress />}
                    options={{
                      pieHole: 0.5,
                      is3D: false,
                      legend: "top",
                      // hAxis: {
                      //   title: "Data",
                      //   format: "d/MM/yyyy",
                      // },
                      // vAxis: {
                      //   title: "Recomendações",
                      // },
                      // focusTarget: "category",
                    }}
                    legendToggle
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={9} mt={2}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  pl={1}
                >
                  <StackedLineChart />
                  <Typography variant={"h4"}>
                    Linha do tempo de recomendações
                  </Typography>
                  <InfoOutlined
                    onMouseEnter={handlePopoverInfoOpen}
                    onMouseLeave={handlePopoverInfoClose}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {recommendersHistoryResult.data?.length === 0 ? (
                  <Typography variant={"h6"}>nenhuma recomendação</Typography>
                ) : (
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={recommendersHistoryResult.data?.map(
                      (row: any[], index: number) => {
                        if (index === 0) return row;
                        return [new Date(row[0]), ...row.slice(1)];
                      }
                    )}
                    loader={<CircularProgress />}
                    options={{
                      legend: "top",
                      hAxis: {
                        title: "Data",
                        format: "d/MM/yyyy",
                      },
                      vAxis: {
                        title: "Recomendações",
                      },
                      focusTarget: "category",
                      explorer: {
                        axis: "horizontal",
                        // keepInBounds: true,
                        maxZoomIn: 4.0,
                      },
                    }}
                    legendToggle
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={recommendersResult.data || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowClick={handleRowClick}
            getRowId={(row) => row._id}
            loading={recommendersResult.isLoading}
            // checkboxSelection
            disableSelectionOnClick
            disableColumnSelector
            disableColumnMenu
            sx={{ height: "61vh" }}
          />
        </Grid>
      </Grid>
      <Paper>
        <Menu
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          sx={{ maxHeight: "60vh" }}
        >
          <Accordion elevation={0}>
            <MenuItem>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Recommend fontSize="small" />
                <Typography>Recomendações</Typography>
              </AccordionSummary>
            </MenuItem>
            <AccordionDetails>
              <Stack spacing={2}>
                {recommendationsTypes.map((el, index) => (
                  <Button key={index} onClick={() => handleFilter(el, "type")}>
                    <Typography>{el}</Typography>
                  </Button>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <MenuItem>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <MenuBook fontSize="small" />
                <Typography>Cursos</Typography>
              </AccordionSummary>
            </MenuItem>
            <AccordionDetails>
              <Stack spacing={2}>
                {recommendationsCourses.map((el, index) => (
                  <Button
                    key={index}
                    onClick={() => handleFilter(el, "course.name")}
                  >
                    <Typography>{el}</Typography>
                  </Button>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
          {filters["course.name"] && (
            <Accordion elevation={0}>
              <MenuItem>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Groups fontSize="small" />
                  <Typography>Turmas</Typography>
                </AccordionSummary>
              </MenuItem>
              <AccordionDetails>
                <Stack spacing={2}>
                  {recommendationsClasses.map((el, index) => (
                    <Button
                      key={index}
                      onClick={() => handleFilter(el, "class.name")}
                    >
                      <Typography>{el}</Typography>
                    </Button>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion elevation={0}>
            <MenuItem>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <School fontSize="small" />
                <Typography>Tutores</Typography>
              </AccordionSummary>
            </MenuItem>
            <AccordionDetails>
              <Stack spacing={2}>
                {recommendationsTutors.map((el, index) => (
                  <Button
                    key={index}
                    onClick={() => handleFilter(el, "tutor.name")}
                  >
                    <Typography>{el}</Typography>
                  </Button>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <MenuItem>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <LocalOffer fontSize="small" />
                <Typography>Etiquetas</Typography>
              </AccordionSummary>
            </MenuItem>
            <AccordionDetails>
              <Stack spacing={2}>
                {recommendationsTags.map((el, index) => (
                  <Button key={index} onClick={() => handleFilter(el, "tag")}>
                    <Typography>{el}</Typography>
                  </Button>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Menu>
      </Paper>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorElDate}
        open={openDate}
        onClose={handleCloseDate}
      >
        <DateRange
          editableDateInputs={true}
          onChange={handleDateChange}
          moveRangeOnFirstSelection={false}
          ranges={state}
          locale={ptBR}
        />
      </Popover>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        id="popover"
        open={openInfo}
        anchorEl={anchorElInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverInfoClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          A linha do tempo de recomendações examina o histórico de análises no
          seu ambiente, para exibir o quantitativo de alunos que apresentam
          problemas nas turmas.
        </Typography>
      </Popover>
      {/* <RecommendationDetails /> */}
    </>
  );
}
