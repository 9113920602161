import { useDispatch, useSelector } from "react-redux";
import { Recommender } from "../../types";
import { RootState } from "../../store";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  Event,
  EventAvailable,
  Groups,
  MenuBook,
  OpenInNew,
  PendingActions,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  Send,
  Summarize,
  Timelapse,
  Timeline,
  Warning,
} from "@mui/icons-material";
import { selectRecommendation } from "../../store/slices/recommendation";
import moment from "moment";
import {
  DataGrid,
  GridCellParams,
  GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetRecommendersHistoryMutation,
  useSendRecommenderByEmailMutation,
} from "../../services/AssisApi";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";

export default function RecommendationDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openSend, setOpenSend] = useState(false);
  const selectedRecommendation: Recommender = useSelector(
    (state: RootState) => state.recommendations.selectedRecommendation
  );
  const [sendRecommender, sendRecommenderResult] =
    useSendRecommenderByEmailMutation();

  // useEffect(() => {
  //   if (selectedRecommendation.class)
  //     recommendersHistory({
  //       "class.classId": selectedRecommendation.class.classId,
  //       instance: instanceId as string,
  //       type: selectedRecommendation.type,
  //     });
  // }, [selectedRecommendation]);

  // const [recommendersHistory, recommendersHistoryResult] =
  //   useGetRecommendersHistoryMutation();

  const handleClose = () => {
    dispatch(selectRecommendation({} as Recommender));
    navigate(-1);
  };

  const formatConclusion = () => {
    let text = "";
    if (selectedRecommendation) {
      if (moment().diff(moment(selectedRecommendation.class?.startDate)) <= 0) {
        text += "0";
      } else if (
        moment().diff(moment(selectedRecommendation.class?.endDate)) >= 0
      ) {
        text += "100";
      } else {
        text += (
          (moment().diff(moment(selectedRecommendation.class?.startDate)) /
            moment(selectedRecommendation.class?.endDate).diff(
              moment(selectedRecommendation.class?.startDate)
            )) *
          100
        ).toFixed(0);
      }
      text += `% (${moment(selectedRecommendation.class?.endDate)
        .add(3, "hours")
        .format("DD/MM/YYYY")})`;
    }
    return text;
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Informe seu email para a confirmação"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar("Enviado com sucesso", {
          variant: "info",
          key: "sending",
        });
        await sendRecommender({
          recommenderId: selectedRecommendation._id,
          email: values.email,
          message: values.message || "",
        });
        if (sendRecommenderResult.isError) throw sendRecommenderResult.error;
        closeSnackbar("sending");
        enqueueSnackbar("Enviado com sucesso", { variant: "success" });
        handleCloseSend();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCloseSend = () => {
    form.resetForm();
    setOpenSend(false);
  };

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  const renderByType = () => {
    switch (selectedRecommendation.type) {
      case "Desempenho dos alunos no curso":
        return StudentsGradeClass(
          selectedRecommendation.list || [],
          selectedRecommendation.percent || 0
        );
      case "Assiduidade dos alunos":
        return StudentsAccessTimeClass(
          selectedRecommendation.list || [],
          selectedRecommendation.percent || 0,
          selectedRecommendation.goodGradeAccessTime || ""
        );
      case "Assiduidade do tutor":
        return TutorAccessTimeClassVSHistorical(
          selectedRecommendation.tutorMeanAccessTime || 0,
          selectedRecommendation.accessTimePercentualDiff || "",
          selectedRecommendation.class?.lastLogin,
          selectedRecommendation.otherTutorsMeanAccessTime || 0,
          selectedRecommendation.averageAccessTime || 0,
          selectedRecommendation.classAverageGrade || 0,
          selectedRecommendation.IAEvasion || 0
        );
      case "Média histórica da turma":
        return AverageGradeClassVSHistorical(
          selectedRecommendation.tutorMeanAccessTime || 0,
          selectedRecommendation.historicalAverageGrade || 0,
          selectedRecommendation.IAEvasion || 0
        );
    }
  };

  return (
    <>
      <Dialog fullScreen open={!!selectedRecommendation.type}>
        <AppBar sx={{ position: "relative", borderRadius: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Detalhes da recomendação
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Fab onClick={handleOpenSend}>
              <Send />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Recommend />
                    <Typography>
                      <strong>Recomendação:</strong>
                    </Typography>
                    <Typography>{selectedRecommendation.type}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <MenuBook />
                    <Typography>
                      <strong>Curso:</strong>
                    </Typography>
                    <Typography>
                      {selectedRecommendation.course?.name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Groups />
                    <Typography>
                      <strong>Turma:</strong>
                    </Typography>
                    <Typography>
                      {selectedRecommendation.class?.name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <School />
                    <Typography>
                      <strong>Tutor:</strong>
                    </Typography>
                    <Typography>
                      {selectedRecommendation.tutor?.name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Percent />
                    <Typography>
                      <strong>Conclusão da turma:</strong>
                    </Typography>
                    <Typography>{formatConclusion()}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Summarize />
                    <Typography>
                      <strong>Análise que disparou a recomendação:</strong>
                    </Typography>
                    <Link
                      to={`/i/${selectedRecommendation.instance}/analises/${selectedRecommendation.batch}/result`}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <Typography>
                          {moment(selectedRecommendation.date)
                            .add(3, "hours")
                            .format("DD/MM/YYYY")}
                        </Typography>
                        <OpenInNew />
                      </Stack>
                      <Typography></Typography>
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {renderByType()}
        </Grid>
      </Dialog>
      <Dialog open={openSend} maxWidth={"md"} fullWidth>
        <Grid
          container
          spacing={2}
          p={2}
          component={"form"}
          onSubmit={form.handleSubmit}
        >
          <Grid item xs={12}>
            <Stack sx={{ alignItems: "center" }} direction={"row"} spacing={1}>
              <Send />
              <Typography sx={{ flexGrow: 1 }}>Encaminhar</Typography>
              <IconButton onClick={handleCloseSend}>
                <Close />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Tutor: {selectedRecommendation.tutor?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid
                  item
                  xs={6}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Recommend />
                    <Typography>Recomendação</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.type}</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <MenuBook />
                    <Typography>Curso</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.course?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Groups />
                    <Typography>Turma</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.class?.name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              hiddenLabel
              placeholder="Insira o email que deseja encaminhar"
              size="small"
              fullWidth
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={
                "email usado por ultimo, ao enviar notificação salvar esse email para sugestão"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              hiddenLabel
              placeholder="Pode enviar uma mensagem junto aos dados"
              size="small"
              fullWidth
              multiline
              value={form.values.message}
              onChange={form.handleChange}
              error={form.touched.message && Boolean(form.errors.message)}
              // helperText={"email usado por ultimo, ao enviar notificação salvar esse email para sugestão"}
            />
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={5}>
            <Button variant="contained" type="submit" fullWidth>
              Enviar mensagem
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

const StudentsGradeClass = (
  list: Record<string, unknown>[],
  percent: number
) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`${list.length} alunos (${percent}% da turma) dos alunos tiveram uma piora no desempenho, com base nas análises dos 2 últimos envios.`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <Recommend />
            <Typography>Recomendação</Typography>
          </Stack>
          <Typography>
            Recomendamos que você acione o tutor para entender o que pode ter
            acontecido. Para isso, algumas sugestões de análise:
          </Typography>
          <ul>
            <li>
              <Typography>
                {" "}
                Verificar a frequência do tutor na turma;{" "}
              </Typography>
            </li>
            <li>
              <Typography>
                Verificar a participação do tutor na turma: interações,
                respostas;
              </Typography>
            </li>
            <li>
              <Typography>
                Identificar possíveis problemas que afetam o desempenho do
                tutor;
              </Typography>
            </li>
            <li>
              <Typography>
                Identificar possíveis erros/dificuldades no conteúdo e/ou na
                formulação das atividades.
              </Typography>
            </li>
          </ul>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={list.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "name",
              headerName: "Aluno",
              flex: 1.5,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <Person />
                  <Typography>Aluno</Typography>
                </Stack>
              ),
            },
            {
              field: "previousAverageGrade",
              headerName: "Média de avaliação",
              flex: 1,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.previousAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.previousAverageGrade || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <Timeline />
                  <Typography>Média de avaliação</Typography>
                </Stack>
              ),
            },
            {
              field: "actualAverageGrade",
              headerName: "Problema",
              flex: 1,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.actualAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.actualAverageGrade || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <RadioButtonChecked />
                  <Typography>Problema</Typography>
                </Stack>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Grid>
    </>
  );
};

const StudentsAccessTimeClass = (
  list: Record<string, unknown>[],
  percent: number,
  goodGradeAccessTime: string
) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`${list.length} alunos (${percent}% da turma) dos alunos têm média de avaliação menor que 7.0 e tempo médio de acesso abaixo dos alunos com melhor desempenho (${goodGradeAccessTime}).`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <Recommend />
            <Typography>Recomendação</Typography>
          </Stack>
          <Typography>Sugerimos que acione o tutor para que ele:</Typography>
          <ul>
            <li>
              <Typography>
                Analise a situação individual na plataforma, a frequência e o
                desempenho nas atividades;
              </Typography>
            </li>
            <li>
              <Typography>
                Entre em contato com o(s) aluno(s) para:
                <ul>
                  <li>
                    <Typography>
                      Conversar com o(a) aluno(a) para entender se o problema é
                      de cunho social, pessoal, cognitivo, didático,
                      motivacional, tecnológico ou de saúde;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Estimular o(a) aluno(a) a interagir e fazer as tarefas;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Enviar mensagens de incentivos motivacionais;
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography>
                Em função da identificação do problema, implementar ações
                pertinentes.
              </Typography>
            </li>
          </ul>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={list.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "name",
              headerName: "Aluno",
              flex: 1.5,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <Person />
                  <Typography>Aluno</Typography>
                </Stack>
              ),
            },
            {
              field: "averageGrade",
              headerName: "Média de avaliação",
              flex: 1,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.averageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.averageGrade || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <Timeline />
                  <Typography>Média de avaliação</Typography>
                </Stack>
              ),
            },
            {
              field: "accessTime",
              headerName: "Problema",
              flex: 1,
              renderCell: (params: GridCellParams<string>) => (
                <Tooltip title={`${params.row?.accessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.accessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: (params: GridColumnHeaderParams) => (
                <Stack spacing={1} direction={"row"}>
                  <RadioButtonChecked />
                  <Typography>Tempo de Acesso</Typography>
                </Stack>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Grid>
    </>
  );
};

const TutorAccessTimeClassVSHistorical = (
  tutorMeanAccessTime: number,
  accessTimePercentualDiff: string,
  lastLogin: Date,
  otherTutorsMeanAccessTime: number,
  averageAccessTime: number,
  classAverageGrade: number,
  IAEvasion: number
) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`Verificamos que o tutor a seguir está dedicando ${tutorMeanAccessTime} horas na plataforma,  ${accessTimePercentualDiff}% a menos que o histórico de outros tutores do curso, podendo impactar no desempenho da turma.`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <Recommend />
            <Typography>Recomendação</Typography>
          </Stack>
          <Typography>
            Sugerimos incentivar a participação do tutor na turma através das
            seguintes ações:
          </Typography>
          <ul>
            <li>
              <Typography>
                Interagir com mais frequência nos ambientes de conversação
                (fóruns e tira-dúvidas);
              </Typography>
            </li>
            <li>
              <Typography>Avaliar e dar feedback em até 24 horas;</Typography>
            </li>
            <li>
              <Typography>Enviar mensagens de motivação aos alunos.</Typography>
            </li>
          </ul>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <EventAvailable />
                <Typography>
                  <strong>Último acesso do tutor:</strong>
                </Typography>
                <Typography>
                  {moment(lastLogin)
                    .add(3, "hours")
                    .format("DD/MM/YYYY [às] hh:mm")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Timelapse />
                <Typography>
                  <strong>Média de acesso do tutor na turma:</strong>
                </Typography>
                <Typography>
                  {`${moment
                    .duration(tutorMeanAccessTime, "hours")
                    .hours()}h ${moment
                    .duration(tutorMeanAccessTime, "hours")
                    .minutes()}min por dia`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Timelapse />
                <Typography>
                  <strong>Média de acesso dos demais tutores:</strong>
                </Typography>
                <Typography>
                  {`${moment
                    .duration(otherTutorsMeanAccessTime, "hours")
                    .hours()}h ${moment
                    .duration(otherTutorsMeanAccessTime, "hours")
                    .minutes()}min por dia`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <PendingActions />
                <Typography>
                  <strong>Assiduidade da turma:</strong>
                </Typography>
                <Typography>
                  {`${moment
                    .duration(averageAccessTime, "hours")
                    .hours()}h ${moment
                    .duration(averageAccessTime, "hours")
                    .minutes()}min total`}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Timeline />
                <Typography>
                  <strong>Média da Turma:</strong>
                </Typography>
                <Typography>{classAverageGrade}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Warning />
                <Typography>
                  <strong>Potencial de evasão médio:</strong>
                </Typography>
                <Typography>{`${IAEvasion}%`}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

const AverageGradeClassVSHistorical = (
  classAverageGrade: number,
  historicalAverageGrade: number,
  IAEvasion: number
) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`Notamos que a turma está com média abaixo da média histórica para o curso.`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <Recommend />
            <Typography>Recomendação</Typography>
          </Stack>
          <Typography>
            A informação pode indicar situações críticas a serem analisadas e
            direcionar algumas ações:
          </Typography>
          <ul>
            <li>
              <Typography>
                Verificar a participação do tutor; se ausente, entrar em contato
                com ele;
              </Typography>
            </li>
            <li>
              <Typography>
                Verificar se houve modificações no conteúdo;
              </Typography>
            </li>
            <li>
              <Typography>
                Solicitar coleta de feedback dos alunos para verificar fatores
                externos (problemas no AVA, avaliação do conteúdo do curso etc).
              </Typography>
            </li>
          </ul>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Timeline />
                <Typography>
                  <strong>Média da Turma:</strong>
                </Typography>
                <Typography>{classAverageGrade}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Timeline />
                <Typography>
                  <strong>Média Histórica:</strong>
                </Typography>
                <Typography>{historicalAverageGrade}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Warning />
                <Typography>
                  <strong>Potencial de evasão médio:</strong>
                </Typography>
                <Typography>{`${IAEvasion}%`}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
