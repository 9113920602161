import {
  Button,
  ButtonBase,
  ButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  Home as HomeIcon,
  Paid as PaymentsIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import Logo from "../../global/images/logo.svg";
import "./styles.sass";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { UserPlan } from "../../types";

interface Props extends ButtonProps {}

export default function MainSidebar({}: Props) {
  const navigate = useNavigate();
  const userInfo = useSelector(
    (state: RootState) => state.auth.authenticatedUser
  );
  return (
    <Paper elevation={3} className="main-sidebar">
      <img className="logo" src={Logo} alt="Logo Assis" />

      <List className="menu">
        <ListItem id="sidebar-panel" disablePadding>
          <ListItemButton
            className="menu-button"
            onClick={() => {
              navigate("/i");
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <Typography variant="subtitle2">Painel Inicial</Typography>
          </ListItemButton>
        </ListItem>

        <ListItem id="sidebar-userSettings" disablePadding>
          <ListItemButton
            className="menu-button"
            onClick={() => {
              navigate("/user/settings");
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Typography variant="subtitle2">
              Configurações de usuário
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  );
}
