import {
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
  useResolvedPath,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store";

import Login from "./screens/Login";
import EnableUser from "./screens/EnableUser";
import Instances from "./screens/Instances";
import MainTemplate from "./templates/MainTemplate";
import Instance from "./screens/Instance";
import { Button, Typography } from "@mui/material";
import NewAnalysis from "./screens/NewAnalysis";
import AnalysisResult from "./screens/AnalysisResult";
import NewInstance from "./screens/NewInstance";
import UserSetting from "./screens/UserSettings";
import Invoices from "./screens/Invoices";
import Plans from "./screens/Plans";
import UserDeleteComfirm from "./screens/UserDeleteConfirm";
import Payment from "./screens/Payment";
import { SnackbarProvider } from "notistack";
import NewCard from "./screens/NewCard";
import EditCard from "./screens/EditCard";
import DeleteCard from "./screens/DeleteCard";
import ConfirmEmail from "./screens/ConfirmEmail";
import PasswordRecovery from "./screens/PasswordRecovery";
import PasswordReset from "./screens/PasswordReset";
import InstanceSettings from "./screens/InstancesSettings";
import InstanceDeleteConfirm from "./screens/InstanceDeleteConfirm";
import BatchDeleteConfirm from "./screens/BatchDeleteConfirm";
import AcceptTerm from "./screens/AcceptTerm";
import RecommendationDetails from "./components/RecommendationDetails";

interface Props {}

export default function Router({}: Props) {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const { authenticated } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {!authenticated && (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/confirm-email/:code" element={<ConfirmEmail />} />
          <Route path="/enable/:code" element={<EnableUser />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          <Route path="/change-password/:code" element={<PasswordReset />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
      {authenticated && (
        <>
          <Routes location={state?.backgroundLocation || location}>
            <Route path="/change-password/:code" element={<PasswordReset />} />
            <Route path="/accept-term" element={<AcceptTerm />} />
            <Route path="/" element={<MainTemplate />}>
              {/* <Route path="/" element={<Instances />} /> */}
              <Route path="i" element={<Instances />} />
              <Route path="/i/:instanceId/analises" element={<Instance />} />
              <Route
                path="/i/:instanceId/analises/recommendation"
                element={<RecommendationDetails />}
              />
              <Route
                path="/i/:instanceId/analises/new"
                element={<NewAnalysis />}
              />
              <Route
                path="/i/:instanceId/settings"
                element={<InstanceSettings />}
              />
              <Route
                path="/i/:instanceId/analises/:batchId/result"
                element={<AnalysisResult />}
              />
              <Route
                path="/i/:instanceId/*"
                element={<Navigate to="analises" />}
              />

              <Route path="/user/settings" element={<UserSetting />} />

              <Route path="/invoices" element={<Invoices />} />

              {/* <Route path="/plans" element={<Plans />} />
              <Route path="/plans/payment" element={<Payment />} /> */}

              <Route
                path="/change-password/:code"
                element={<PasswordReset />}
              />

              <Route path="/" element={<Navigate to="/i" />} />
              <Route path="*" element={<Navigate to="/i" />} />
            </Route>
          </Routes>
          {state?.backgroundLocation && (
            <Routes>
              <Route path="/i/new" element={<NewInstance />} />
              <Route
                path="/user/delete/confirm"
                element={<UserDeleteComfirm />}
              />
              <Route path="/invoices/new-card" element={<NewCard />} />
              <Route path="/invoices/delete-card" element={<DeleteCard />} />
              <Route path="/plans/payment/new-card" element={<NewCard />} />
              <Route path="/plans/payment/edit-card" element={<EditCard />} />
              <Route
                path="/plans/payment/delete-card"
                element={<DeleteCard />}
              />
              <Route
                path="/i/:instanceId/settings/delete-confirm"
                element={<InstanceDeleteConfirm />}
              />
              <Route
                path="/i/:instanceId/analises/:batchId/delete-confirm"
                element={<BatchDeleteConfirm />}
              />
            </Routes>
          )}
        </>
      )}
    </>
  );
}
