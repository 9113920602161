import Screen from "../../components/Screen";
import {
  DataGrid,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NewAnalysisIcon from "@mui/icons-material/AddCircleOutline";
import EmptyImg from "./images/empty.png";
import SetaImg from "./images/seta.png";
import "./styles.sass";
import { useGetIntanceBatchsQuery } from "../../services/AssisApi";
import { MouseEvent, useEffect, useState } from "react";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile } from "../../services/Firebase";
import {
  CalendarMonth,
  Feed,
  InfoOutlined,
  Outbox,
  ToggleOn,
  Visibility,
} from "@mui/icons-material";
import { Instance as InstanceType, ReactRoutesState } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Box } from "@mui/system";

interface Props {}

type Row = {
  id: string;
  date: Date;
  sendType: string;
  processStatus: string;
  processResponse: string;
  info: string;
  file: string;
};

export default function Batchs({}: Props) {
  const navigate = useNavigate();
  let { instanceId } = useParams();
  const location = useLocation();
  // const selectedInstance: InstanceType = useSelector(
  //   (state: RootState) => state.app.selectedInstance
  // );
  const [rows, setRows] = useState<Row[]>([]);
  const { data, error, isLoading, refetch } = useGetIntanceBatchsQuery(
    instanceId || "",
    { refetchOnMountOrArgChange: true }
  );

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [popoverText, setPopoverText] = useState<String>("");

  const handlePopoverOpen = (
    event: MouseEvent<SVGSVGElement>,
    text: String
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const columns: GridColumns = [
    {
      field: "see",
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <IconButton>
            <Visibility fontSize="small" color="primary" />
          </IconButton>
        </>
      ),
    },
    {
      field: "processStatus",
      headerName: "Status",
      flex: 0.1,
      sortComparator: (v1, v2) => {
        let textv1 = "";
        let textv2 = "";
        switch (v1) {
          case "proccesing":
            textv1 = "Processando";
            break;
          case "success":
            textv1 = "Concluído";
            break;
          case "failure":
            textv1 = "Falha";
            break;
        }
        switch (v2) {
          case "proccesing":
            textv2 = "Processando";
            break;
          case "success":
            textv2 = "Concluído";
            break;
          case "failure":
            textv2 = "Falha";
            break;
        }
        return textv1.localeCompare(textv2);
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <ToggleOn />
          <Typography>Status</Typography>
        </Stack>
      ),
      renderCell: (params: GridRenderCellParams<string>) => {
        let text = "";
        let color = "";
        switch (params.value) {
          case "proccesing":
            text = "Processando";
            color = "#1AB1F5";
            break;
          case "success":
            text = "Concluído";
            color = "#5CB660";
            break;
          case "failure":
            text = "Falha";
            color = "#F06360";
            break;
        }
        return (
          <>
            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  color: color,
                  fontWeight: 700,
                }}
              >
                {text}
              </Typography>
              <InfoOutlined
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, params.row.processResponse)
                }
                onMouseLeave={handlePopoverClose}
              />
            </Stack>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Data",
      description: "A data em que a análise foi enviada",
      flex: 0.1,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) =>
        moment(params.value?.toString()).format("DD/MM/YYYY HH:mm"),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Tooltip title="A data em que a análise foi enviada">
          <Stack spacing={1} direction={"row"}>
            <CalendarMonth />
            <Typography>Data de envio</Typography>
          </Stack>
        </Tooltip>
      ),
    },
    {
      field: "sendType",
      headerName: "Modo de envio",
      flex: 0.15,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <Outbox />
          <Typography>Modo de envio</Typography>
        </Stack>
      ),
    },
    {
      field: "info",
      headerName: "Informações do envio",
      flex: 0.2,
      filterable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack spacing={1} direction={"row"}>
          <Feed />
          <Typography>Resumo de informações</Typography>
        </Stack>
      ),
    },

    {
      field: "id",
      filterable: false,
      headerName: "",
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              downloadFile(params.row.file);
            }}
          >
            <DownloadIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`${params.value}/delete-confirm`, {
                state: { backgroundLocation: location },
              });
            }}
          >
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    navigate(`${params.id}/result`);
  };

  useEffect(() => {
    let rows: Row[] = [];
    if (data) {
      data.forEach((batch) => {
        rows.push({
          id: batch._id,
          date: batch.startAt,
          sendType: "Upload de arquivo",
          processStatus: batch.processStatus,
          processResponse: batch.response,
          info: `${batch.courseCount} cursos, ${batch.tutorCount} tutores e ${batch.studentCount} alunos`,
          file: batch.file,
        });
      });
      setRows(rows);
    }
  }, [data]);

  return (
    <>
      <div id="new-batch-wrapper">
        <Button
          variant="contained"
          onClick={() => {
            navigate("new");
          }}
        >
          <NewAnalysisIcon />
          Nova análise
        </Button>
      </div>
      {rows.length === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6} alignSelf={"end"}>
            <Typography variant={"h6"} textAlign={"center"}>
              Clique para criar uma nova análise
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign={"center"}>
            <img
              src={SetaImg}
              className="seta-img"
              alt="Seta para enviar nova analise"
            />
          </Grid>

          <Grid item xs={3}></Grid>
          <Grid item xs={6} textAlign={"center"}>
            <img
              src={EmptyImg}
              className="empty-img"
              alt="Nenhuma análise feita"
            />
          </Grid>
        </Grid>
      )}
      {rows.length > 0 && (
        // <Box sx={{ height: "82.1vh" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onRowClick={handleRowClick}
          // checkboxSelection
          disableSelectionOnClick
          disableColumnSelector
          disableColumnMenu
          autoHeight
        />
        // </Box>
      )}

      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popoverText}</Typography>
      </Popover>
    </>
  );
}
